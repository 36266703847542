<template lang="pug">
#battery-confirmation(v-esc-close="performAction")
  .modal-header
    h2 Please dispose of the batteries and spinner device according to your local laws.
  .modal-footer
    button.button.action-btn(@click="performAction") Continue
</template>

<script>
export default {
  props: {
    onAction: { type: Function, required: true },
  },

  data() {
    return {}
  },

  computed: {},

  watch: {},

  methods: {
    performAction,
    close,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
async function performAction() {
  this.onAction()
  this.$modal.closeAll()
}
function close() {
  this.$modal.close('BatteryConfirmation')
}
</script>
